import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

type NextRoundButtonType = {
  text: string;
  onClick: () => void;
};

const BUTTON_ANIMATION_DURATION = 0.15;
const BUTTON_ANIMATION_DELAY = 0.05;

const Button = styled.button`
  ${({ theme }) => `
width: ${theme.getValueWidth(35)}px;
height: ${theme.getValueWidth(12)}px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 0;
color: ${theme.colors.green};
font-weight: bold;
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(2.5)}px;
line-height: ${theme.getValueHeight(2.5)}px;
padding: ${theme.getValueWidth(2)}px ${theme.getValueWidth(4)}px;
border-radius: ${theme.getValueWidth(1)}px;
background: #f2f2e1;
border: solid 1px ${theme.colors.skyblue};
position: absolute;
z-index: 3;
&.pressed {
  animation: pressed-in-next ${BUTTON_ANIMATION_DURATION}s forwards;
}
&.notpressed {
  animation: pressed-out-next ${BUTTON_ANIMATION_DURATION}s forwards;
}
`}
`;

const BackButton = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(35)}px;
height: ${theme.getValueWidth(12)}px;
background: ${theme.colors.blue};
position: absolute;
top: ${theme.getValueHeight(0.5)}px;
border-radius: ${theme.getValueWidth(1)}px;
z-index: 2;
`}
`;

const ButtonContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(35)}px;
height: ${theme.getValueWidth(12)}px;
display: flex;
position: relative;
z-index: 2;
margin: ${theme.getValueHeight(4)}px 0 0 0;
@keyframes pressed-out-next {
  0% {
    top: ${theme.getValueHeight(3)}px;
  }
  100% { 
    top: 0;
  }
}

@keyframes pressed-in-next {
  0% {
    top: 0;
  }
  100% { 
    top: ${theme.getValueHeight(3)}px;
  }
}

`}
`;

const NextRoundButton: FC<NextRoundButtonType> = ({ text, onClick }: NextRoundButtonType) => {
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, BUTTON_ANIMATION_DELAY * 1000);
  }, [onClick]);

  return (
    <ButtonContainer>
      <Button className={isPressed ? 'pressed' : 'notpressed'} onClick={onClick}>
        {text}
      </Button>
      <BackButton />
    </ButtonContainer>
  );
};

export default NextRoundButton;
