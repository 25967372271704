import { FC } from 'react';
import styled from 'styled-components';

type TextBoxType = {
  text: string;
  className?: string;
};

const FIRST_ANIMATION_DELAY = 2;
const SINGLE_ANIMATION_DURATION = 1;

const Container = styled.div`
  ${({ theme }) => `


@keyframes appear {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
  100% { 
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
}


width: ${theme.getValueWidth(32)}px;
height: ${theme.getValueHeight(19)}px;
display: flex;
flex-direction: row;
position: relative;
left: ${theme.getValueWidth(15)}px;
top: ${theme.getValueHeight(4)}px;

animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY}s;
opacity:0;

&.hidding {

  animation: disappear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: 0s;
}
`}
`;

const Text = styled.p`
  ${({ theme }) => `
width: ${theme.getValueWidth(15)}px;
height: ${theme.getValueWidth(12.5)}px;
display: flex;
flex-direction: column;
margin: 0;
color: ${theme.colors.green};
font-family: ${theme.fonts.cadiz};
font-weight: bold;
font-size: ${theme.getValueHeight(1.5)}px;
padding: ${theme.getValueWidth(2)}px ${theme.getValueWidth(4)}px;
background: ${theme.colors.skyblue};
border-radius: ${theme.getValueWidth(3)}px;
border: solid 1px ${theme.colors.skyblue};
@media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueHeight(1.8)}px;
  }
`}
`;

const Arrow = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(10)}px;
height: ${theme.getValueWidth(7)}px;
display: flex;
flex-direction: column;
background: linear-gradient(135deg,${theme.colors.skyblue} 22px,transparent 0);
margin: ${theme.getValueWidth(3)}px 0 0 0;

`}
`;

const TextBox: FC<TextBoxType> = ({ text, className = '' }: TextBoxType) => {
  return (
    <Container className={className}>
      <Text>{text}</Text>
      <Arrow />
    </Container>
  );
};

export default TextBox;
