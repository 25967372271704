/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Header from '../components/Header';
import Disc from '../assets/images/musicDiskWhite.png';
import ProductsImage from '../assets/images/productsImage.png';

const FIRST_ANIMATION_DELAY = 0.5;
const SINGLE_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY = FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION * 2;

const Container = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
position: relative;
align-items: center;
justify-content: flex-start;
flex-direction: column;
overflow: hidden;
background: rgb(143,205,213);
background: linear-gradient(180deg, rgba(143,205,213,1) 0%, rgba(243,250,251,1) 100%);

@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
  100% { 
    opacity: 1;
    left: 0; 
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}
`}
`;

const TextContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: auto;
display: flex;
flex-direction: column;
z-index: 1;
@media (${theme.breakpoints.mobileL}) {
    height: ${theme.getValueHeight(50.5)}px;
  }
`}
`;

const TitleBack = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.blue};
  font-size: ${theme.getValueWidth(15.75)}px;
  width: ${theme.getValueWidth(80)}px;
  word-break: break-word;
  padding: ${theme.getValueHeight(4)}px ${theme.getValueWidth(10)}px ${theme.getValueHeight(2)}px;
  margin: 0;
  @media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(14)}px;
    max-width: ${theme.getValueWidth(70)}px;
  }
  height: ${theme.getValueHeight(30)}px;
  position: relative;
  
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Title = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.white};
  font-size: ${theme.getValueWidth(15.5)}px;
  width: ${theme.getValueWidth(80)}px;
  word-break: break-word;
  padding: ${theme.getValueHeight(4)}px ${theme.getValueWidth(10)}px ${theme.getValueHeight(0)}px;
  margin-top: -${theme.getValueHeight(36.35)}px;
  height: ${theme.getValueHeight(30)}px;
  @media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(14)}px;
    margin-top: -${theme.getValueHeight(36.65)}px;
    max-width: ${theme.getValueWidth(70)}px;
  }
  position: relative;
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Text = styled.p`
  ${({ theme }) => `
width: ${theme.getValueWidth(75)}px;
height: auto;
color: ${theme.colors.green};
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(2)}px;
padding: 0 ${theme.getValueWidth(15)}px ${theme.getValueHeight(2)}px ${theme.getValueWidth(10)}px;
margin: 0;
@media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(4)}px;
  }
position: relative;
animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION}s;
opacity: 0;
`}
`;

const DiscImg = styled.img`
  ${({ theme }) => `
  width: ${theme.getValueWidth(100)}px;
  height: ${theme.getValueHeight(90)}px;
  display: flex;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  opacity: 0.25;
  top: ${theme.getValueHeight(4)}px;
`}
`;

const InputWrapper = styled.div`
  ${({ theme }) => `
  width: ${theme.getValueWidth(100)}px;
  padding-left: ${theme.getValueWidth(20)}px;
  margin-top: ${theme.getValueHeight(2)}px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Input = styled.input`
  ${({ theme }) => `
  width: ${theme.getValueWidth(35)}px;
  height: ${theme.getValueHeight(5)}px;
  border-radius: ${theme.getValueWidth(2)}px;
  font-size: ${theme.getValueHeight(1.5)}px;
  padding: 0px ${theme.getValueWidth(2)}px;
  margin-bottom: ${theme.getValueHeight(2.5)}px;
  border: 0px;
  color: ${theme.colors.green};
`}
`;

const Button = styled.button`
  ${({ theme }) => `
  width: ${theme.getValueWidth(38)}px;
  height: ${theme.getValueHeight(5)}px;
  font-family: ${theme.fonts.blenny};
  border-radius: ${theme.getValueWidth(2)}px;
  font-size: ${theme.getValueHeight(2)}px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.green2};
  border: 0px;
`}
`;

const ProductsImg = styled.img`
  ${({ theme }) => `
  width: ${theme.getValueWidth(40)}px;
  height: ${theme.getValueHeight(22)}px;
  display: flex;
  position: absolute;
  object-fit: contain;
  z-index: 1;
  right: ${theme.getValueWidth(5)}px;
  top: ${theme.getValueHeight(65)}px;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const SmallText = styled.p`
  ${({ theme }) => `
  position: absolute;
  z-index: 1;
  right: ${theme.getValueWidth(4)}px;
  top: ${theme.getValueHeight(88)}px;
  width: ${theme.getValueWidth(40)}px;
  height: ${theme.getValueHeight(22)}px;
  color: ${theme.colors.green};
  font-family: ${theme.fonts.cadiz};
  font-size: ${theme.getValueHeight(1.37)}px;
  margin: 0;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

function Form() {
  return (
    <Container>
      <DiscImg src={Disc} />
      <Header text="sponsored by" />
      <TextContainer>
        <TitleBack>Applause all around.</TitleBack>
        <Title>Applause all around.</Title>
        <Text>
          Keep the music going and enter the Neutrogena® sweepstakes for a chance to win{' '}
          <strong>two Coachella tickets.</strong>
        </Text>
      </TextContainer>
      <InputWrapper>
        <Input placeholder="Name" />
        <Input placeholder="Email" />
        <Button>Enter</Button>
      </InputWrapper>
      <ProductsImg src={ProductsImage} />
      <SmallText>
        And make sure you have the right essentials to help you make the best of your sunny vibes!
        Scan QR code to visit the store.
      </SmallText>
    </Container>
  );
}

export default Form;
