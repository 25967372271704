import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

type OptionType = {
  option: {
    label: string;
    status: boolean;
  };
  selected: string;
  onClick: () => void;
};

const BUTTON_ANIMATION_DURATION = 0.15;
const BUTTON_ANIMATION_DELAY = 0.01;

const Button = styled.button`
  ${({ theme }) => `
width: ${theme.getValueWidth(45)}px;
height: ${theme.getValueWidth(15)}px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 0;
font-weight: bold;
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(2.5)}px;
padding: ${theme.getValueWidth(2)}px ${theme.getValueWidth(4)}px;
border-radius: ${theme.getValueWidth(1)}px;
border: solid 1px ${theme.colors.skyblue};
position: absolute;
z-index: 3;
&.pressed {
  animation: pressed-in ${BUTTON_ANIMATION_DURATION}s forwards;
}
&.notpressed {
  animation: pressed-out ${BUTTON_ANIMATION_DURATION}s forwards;
}
`}
`;

const BackButton = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(45)}px;
height: ${theme.getValueWidth(15)}px;
background: ${theme.colors.blue};
position: absolute;
top: ${theme.getValueHeight(0.5)}px;
border-radius: ${theme.getValueWidth(1)}px;
z-index: 2;
`}
`;

const ButtonContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(45)}px;
height: ${theme.getValueWidth(15)}px;
display: flex;
position: relative;
z-index: 2;

@keyframes pressed-out {
  0% {
    top: ${theme.getValueHeight(0.5)}px;
  }
  100% { 
    top: 0;
  }
}

@keyframes pressed-in {
  0% {
    top: 0;
  }
  100% { 
    top: ${theme.getValueHeight(0.5)}px;
  }
}

`}
`;

const Option: FC<OptionType> = ({ option, selected, onClick }: OptionType) => {
  const [bgColor, setBgColor] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setIsPressed(false);
    if (option.label === selected && option.status === false) {
      setBgColor('#dd5e80');
      setFontColor('#ffffff');
      setIsPressed(true);
      setTimeout(() => {
        setIsPressed(false);
      }, BUTTON_ANIMATION_DELAY * 1000);
      return;
    }
    if (option.label === selected && option.status === true) {
      setBgColor('#acc834');
      setFontColor('#ffffff');
      setIsPressed(true);
      setTimeout(() => {
        setIsPressed(false);
      }, BUTTON_ANIMATION_DELAY * 1000);
      return;
    }
    if (option.label !== selected && selected !== '') {
      setBgColor('#ffffff');
      setFontColor('#1d797b');
      return;
    }
    setBgColor('#ffffff');
    setFontColor('#1d797b');
  }, [selected]);

  return (
    <ButtonContainer>
      <Button
        className={isPressed ? 'pressed' : 'notpressed'}
        style={{ backgroundColor: bgColor, color: fontColor }}
        onClick={onClick}>
        {option.label}
      </Button>
      <BackButton />
    </ButtonContainer>
  );
};

export default Option;
