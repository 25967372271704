/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import Header from '../components/Header';
import Needle from '../assets/images/needle.png';
import Disc from '../assets/images/musicDisk.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const FIRST_ANIMATION_DELAY = 0.5;
const SINGLE_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY = FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION * 2;
const NEDDLE_ANIMATION_DURATION = 0.5;
const NEDDLE_ANIMATION_DELAY = 0.5;
const NEEDLE_COMPLETE_ANIMATION_TIME_MS = 2300;

const Container = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
overflow: hidden;
background: rgb(143,205,213);
background: linear-gradient(180deg, rgba(143,205,213,1) 0%, rgba(243,250,251,1) 100%);
@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
  100% { 
    opacity: 1;
    left: 0; 
  }
}

`}
`;

const TextContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: auto;
display: flex;
flex-direction: column;
position: relative;
@media (${theme.breakpoints.mobileL}) {
    height: ${theme.getValueHeight(50.5)}px;
  }
`}
`;

const Title = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.white};
  font-size: ${theme.getValueWidth(15.5)}px;
  width: ${theme.getValueWidth(80)}px;
  word-break: break-word;
  padding: ${theme.getValueHeight(8)}px ${theme.getValueWidth(10)}px ${theme.getValueHeight(2)}px;
  margin: 0;
  @media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(14)}px;
  }
  position: relative;
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Text = styled.p`
  ${({ theme }) => `
width: ${theme.getValueWidth(75)}px;
height: auto;
color: ${theme.colors.green};
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(2)}px;
padding: 0 ${theme.getValueWidth(15)}px ${theme.getValueHeight(2)}px ${theme.getValueWidth(10)}px;
margin: 0;
@media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(3)}px;
  }
position: relative;
animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION}s;
opacity: 0;
`}
`;

const ImageContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(48.5)}px;
display: flex;
flex-direction: column;
overflow: hidden;
`}
`;

const NeedleContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: auto;
display: flex;
position: absolute;
overflow: hidden;

@keyframes slide-in-needle {
  0% {
    opacity: 0;
    right: -${theme.getValueWidth(65)}px;
  }
  100% { 
    opacity:1;
    right: ${theme.getValueWidth(-15)}px; 
  }
}

.home-text {
  visibility: visible;
  transition: visibility 1s;
  transition-delay: 1s;
  transition-timing-function: ease-in-out;

}

&.isClicked {
    img {
      transform: rotate(31deg);
      top: 39px;
    }
    .home-text {
      opacity: 0;
      visibility: hidden;
    }
  }
`}
`;

const NeedleHome = styled.img`
  ${({ theme }) => `
width: ${theme.getValueWidth(65)}px;
height: auto;
transform: rotate(70deg);
display: flex;
position: relative;
right: ${theme.getValueWidth(-15)}px;
top: ${theme.getValueHeight(-1)}px;
object-fit: cover;

animation: slide-in-needle ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
opacity:  0;
transition: transform ${NEDDLE_ANIMATION_DURATION}s, top ${NEDDLE_ANIMATION_DURATION}s;
transition-delay: ${NEDDLE_ANIMATION_DELAY}s;
`}
`;

const DiscContainer = styled.div`
  ${({ theme }) => `

  @keyframes slide-in-disc{
    from {opacity:0; bottom:-${theme.getValueHeight(48.5)}px;}
    to {opacity:1; bottom: 0;  }
  }

  width: ${theme.getValueWidth(100)}px;
  height: ${theme.getValueHeight(48.5)}px;
  display: flex;
  position: relative;
  overflow: hidden;
  animation:slide-in-disc ${SINGLE_ANIMATION_DURATION}s forwards ;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity:0;
`}
`;

const DiscHome = styled.img`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(65)}px;
display: flex;
position: relative;
object-fit: cover;
top: ${theme.getValueHeight(16)}px;
`}
`;

function Home() {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  });

  const goToRounds = () => {
    setIsClicked(true);

    setTimeout(() => {
      navigate('/rounds');
      setIsClicked(false);
    }, NEEDLE_COMPLETE_ANIMATION_TIME_MS);
  };

  return (
    <Container>
      <Header text="sponsored by" />
      <TextContainer>
        <Title>Name the Coachella Artist</Title>
        <Text>
          Can you recognize these performers? Put your festival readiness to the test and you could{' '}
          <strong>WIN two tickets to Coachella</strong>{' '}
        </Text>
      </TextContainer>
      {isRendered && (
        <ImageContainer>
          <DiscContainer>
            <DiscHome src={Disc} />
          </DiscContainer>

          <NeedleContainer className={isClicked ? 'isClicked' : ''}>
            <TextBox
              text="Drop the needle to start"
              className={`${isClicked ? 'home-text hidding' : 'home-text'}`}
            />
            <NeedleHome src={Needle} onClick={() => goToRounds()} />
          </NeedleContainer>
        </ImageContainer>
      )}
    </Container>
  );
}

export default Home;
