/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import whiteDisc from '../assets/images/musicDiskWhite.png';
import Needle from '../assets/images/needle.png';
import Disc from '../assets/images/musicDisk.png';
import Header from '../components/Header';
import { Option as StyledOption } from '../components/Option';
import NextRoundButton from '../components/NextRound';
import { useNavigate } from 'react-router-dom';
import Song1 from '../assets/songs/ThinkinBoutYou-FrankOcean.mp3';
import Song2 from '../assets/songs/FeelSoClose-CalvinHarris.mp3';
import Song3 from '../assets/songs/FeelGoodInc-Gorillaz.mp3';
import Song4 from '../assets/songs/BoomClap-CharliXCX.mp3';

const FIRST_ANIMATION_DELAY = 0.6;
const TITLE_ANIMATION_DURATION = 0.5;
const SUBTITLE_ANIMATION_DURATION = 0.5;
const TITLE_ANIMATION_TRANSITION_DURATION = 0.15;
const REPEAT_SONG_TITLE_ANIMATION_DURATION = 0.25;
const REPEAT_SONG_DELAY = 1;
const SINGLE_ANIMATION_DURATION = 1;
const SUCCESS_ANIMATION_DURATION = 0.8;
const SUCCESS_ANIMATION_DELAY = 1;
const GO_TO_FORM_SCREEN = 4;
const BOTTOM_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY = 0.5;
const NEEDLE_ANIMATION_DURATION = 1.3;
const REPLAY_ANIMATION_DURATION = 0.5;
const REPLAY_ANIMATION_DELAY = 0.6;
const NEXT_ROUND_SONG_DELAY_DURATION = 2;

const Container = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
background: rgb(143,205,213);
background: linear-gradient(180deg, rgba(143,205,213,1) 0%, rgba(243,250,251,1) 100%);
z-index: 0;
overflow: hidden;

@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
  100% { 
    opacity: 1;
    left: 0;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    left: 0;
  }
  100% { 
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
}

@keyframes appearBottom {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}

@keyframes disappearBottom {
  0% {
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
  100% { 
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% { 
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% { 
    opacity: 0;
    transform: scale(0.2);
  }
}

`}
`;

const BackgroundContainer = styled.div`
  ${({ theme }) => `
display: flex;
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
position: absolute;
overflow: hidden;
z-index: 1;
`}
`;

const Background = styled.img`
  ${({ theme }) => `
display: flex;
align-items: center;
justify-content: center;
width: ${theme.getValueWidth(175)}px;
height: ${theme.getValueHeight(100)}px;
position: relative;
padding: ${theme.getValueHeight(4)}px 0;
left: ${theme.getValueWidth(-30)}px;
opacity: 0.15;
z-index: 0;
`}
`;

const QuestionContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
position: relative

`}
`;

const TitleContainer = styled.div`
  ${({ theme }) => `
display: flex;
flex-direction: row
width: ${theme.getValueWidth(60)}px;
position: relative;
margin: 0;
z-index: 2;
`}
`;

const Title = styled.h1<{ textLenght: number }>`
  ${({ theme, textLenght }) => `
height: ${theme.getValueHeight(15)}px;
font-family: ${theme.fonts.blenny};
color: ${theme.colors.white};
font-size: ${textLenght > 25 ? theme.getValueWidth(7.5) : theme.getValueWidth(10)}px;
width: ${theme.getValueWidth(86)}px;
padding: ${theme.getValueHeight(4)}px ${theme.getValueWidth(7)}px ${theme.getValueHeight(1)}px;
margin: 0;
z-index: 3;
position: relative;
opacity: 0;
&.appear {
  animation: slide-in ${TITLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
}
&.disappear {
  animation: slide-out ${TITLE_ANIMATION_DURATION}s forwards;
}
`}
`;

const SubtitleContainer = styled.div`
  ${({ theme }) => `
display: flex;
flex-direction: row
width: ${theme.getValueWidth(60)}px;
margin: 0;
z-index: 2;
position: relative;
`}
`;

const Subtitle = styled.h2`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
color: ${theme.colors.green};
font-size: ${theme.getValueWidth(8)}px;
width: ${theme.getValueWidth(43)}px;
padding: 0 0 0 ${theme.getValueWidth(7)}px;
margin: 0;
position: relative;
opacity: 0;
&.appear {
  animation: slide-in ${SUBTITLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
}
&.disappear {
  animation: slide-out ${SUBTITLE_ANIMATION_DURATION}s forwards;
}
`}
`;

const ResultContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(50)}px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
@media (${theme.breakpoints.mobileL}) {
    height: ${theme.getValueHeight(47.5)}px;
  }
`}
`;

const SuccessContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(80)}px;
height: ${theme.getValueHeight(43.5)}px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background:rgba(243,250,251,0.35);
margin: ${theme.getValueHeight(2)}px ${theme.getValueWidth(10)}px;
border-radius: ${theme.getValueHeight(1)}px;
opacity: 0;
z-index: 2;
&.zoomIn {
  animation: zoom-in ${SUCCESS_ANIMATION_DURATION}s forwards;
  animation-delay: ${SUCCESS_ANIMATION_DELAY}s;
}
&.zoomOut {
  animation: zoom-out ${SUCCESS_ANIMATION_DURATION}s forwards;
}
`}
`;

const SuccessSong = styled.p`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.purple2};
  font-size: ${theme.getValueWidth(11)}px;
  width: ${theme.getValueWidth(70)}px;
  padding: 0 0 ${theme.getValueHeight(4)}px 0;
  margin: 0;
  text-align: center;
`}
`;

const SuccessArtist = styled.p`
  ${({ theme }) => `
  display: flex;
  justify-content: flex-end;
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.purple2};
  font-size: ${theme.getValueWidth(4)}px;
  width: ${theme.getValueWidth(70)}px;
  padding: 0;
  margin: 0;
`}
`;

const NeedleContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(50)}px;
display: flex;
position: absolute;
`}
`;

const NeedleRound = styled.img`
  ${({ theme }) => `
  width: ${theme.getValueWidth(40)}px;
  height: auto;
  transform: rotate(0deg);
  display: flex;
  position: relative;
  right: ${theme.getValueWidth(-60)}px;
  top: ${theme.getValueHeight(3)}px;
  object-fit: contain;
  z-index: 2;


  transition: transform ${NEEDLE_ANIMATION_DURATION}s,  top 1s;

  &.needle-up {
    transform: rotate(82deg);
    top: ${theme.getValueWidth(-32)}px;
    right: ${theme.getValueWidth(-61)}px;
  }


  &.needle-down {
    transform: rotate(0deg);
    top: ${theme.getValueHeight(3)}px;
    right: ${theme.getValueWidth(-60)}px;
  }

`}
`;

const DiscRound = styled.img`
  ${({ theme }) => `


@keyframes rotation{
    0% {
      width:${theme.getValueWidth(85)}px;
      height: ${theme.getValueHeight(85)}px;
    }
    50% {  
      width: ${theme.getValueWidth(84.5)}px;
      height: ${theme.getValueHeight(84.5)}px;
    }
    100% { 
      width:${theme.getValueWidth(85)}px;
      height: ${theme.getValueHeight(85)}px;
  }
}


@keyframes rotation-lg{
  0% {
      width: ${theme.getValueWidth(75)}px;
      height: ${theme.getValueHeight(75)}px;
  }
  50% {  
      width: ${theme.getValueWidth(74.5)}px;
      height: ${theme.getValueHeight(74.5)}px;
  }
  100% { 
      width: ${theme.getValueWidth(75)}px;
      height: ${theme.getValueHeight(75)}px;
  }
}

  width: ${theme.getValueWidth(85)}px;
  height: ${theme.getValueHeight(85)}px;
  display: flex;
  position: relative;
  object-fit: contain;
  

  &.run-animation {
    animation: rotation ${SINGLE_ANIMATION_DURATION}s forwards;
    animation-delay: ${FIRST_ANIMATION_DELAY}s;
    animation-iteration-count: infinite;

    @media (${theme.breakpoints.mobileL}) {
      animation: rotation-lg ${SINGLE_ANIMATION_DURATION}s forwards;
      animation-delay: ${FIRST_ANIMATION_DELAY}s;
      animation-iteration-count: infinite;
    }
  }

@media (${theme.breakpoints.mobileL}) {
  width: ${theme.getValueWidth(75)}px;
  height: ${theme.getValueHeight(75)}px;
  }
`}
`;

const AnswerContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(20)}px;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
z-index: 3;
gap: ${theme.getValueWidth(2)}px;
opacity: 0;
&.appear {
  animation: appearBottom ${BOTTOM_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
}
&.disappear {
  animation: disappearBottom ${BOTTOM_ANIMATION_DURATION}s forwards;
}
`}
`;

const MessageContainer = styled.div`
  ${({ theme }) => `
  background: rgba(243, 250, 251, 0.8);
  color: ${theme.colors.green};
  font-family: ${theme.fonts.cadiz};
  font-size: ${theme.getValueHeight(2)}px;
  position: absolute;
  z-index: 5;
  width:${theme.getValueWidth(35)}px;
  border-radius: 6px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  opacity: 0;

  p {
    padding: ${theme.getValueWidth(0.3)}px ${theme.getValueWidth(5)}px;
  }

  &.zoomIn {
  animation: zoom-in ${REPLAY_ANIMATION_DURATION}s forwards;
  animation-delay: ${REPLAY_ANIMATION_DELAY}s;
  }
  &.zoomOut {
    animation: zoom-out ${REPLAY_ANIMATION_DURATION}s forwards;
  }
`}
`;

const messages = {
  needle: 'Drop the needle and try again',
  nextRound: 'Next Round',
  replay: 'Try again',
};

const roundsData = [
  {
    messages: {
      round: 'Round 1',
      default: 'Name the Coachella Artist',
      correct: 'Sounds about... RIGHT!',
      error: 'Playing it by car? Try again.',
    },
    song: {
      name: 'Thinkin Bout You',
      link: new Audio(Song1),
      artist: 'Frank Ocean',
    },
    options: [
      {
        label: 'Bad Bunny',
        status: false,
      },
      {
        label: 'Frank Ocean',
        status: true,
      },
      {
        label: 'Burna Boy',
        status: false,
      },
      {
        label: 'TESTPILOT',
        status: false,
      },
    ],
  },
  {
    messages: {
      round: 'Round 2',
      default: 'Name the Coachella Artist',
      correct: 'You are on a (drum) roll!',
      error: 'You got knocked down, but you get up again.',
    },
    song: {
      name: 'Feel so Close',
      link: new Audio(Song2),
      artist: 'Calvin Harris',
    },
    options: [
      {
        label: 'Calvin Harris',
        status: true,
      },
      {
        label: 'Bjork',
        status: false,
      },
      {
        label: 'boygenius',
        status: false,
      },
      {
        label: 'The Chemical Brothers',
        status: false,
      },
    ],
  },
  {
    messages: {
      round: 'Round 3',
      default: 'Name the Coachella Artist',
      correct: 'Sounds about... RIGHT!',
      error: 'No rhythim. Try again.',
    },
    song: {
      name: 'Feel Good Inc',
      link: new Audio(Song3),
      artist: 'Gorillaz',
    },
    options: [
      {
        label: 'Rosalia',
        status: false,
      },
      {
        label: 'GloRilla',
        status: false,
      },
      {
        label: 'Gorillaz',
        status: true,
      },
      {
        label: 'Fisher',
        status: false,
      },
    ],
  },
  {
    messages: {
      round: 'Round 4',
      default: 'Name the Coachella Artist',
      correct: 'By leaps and sounds, you did it',
      error: 'Off hey! Try again.',
    },
    song: {
      name: 'Boom Clap',
      link: new Audio(Song4),
      artist: 'Charlie XCX',
    },
    options: [
      {
        label: 'Charlie XCX',
        status: true,
      },
      {
        label: '$uicide boy$',
        status: false,
      },
      {
        label: 'Angele',
        status: false,
      },
      {
        label: 'Blondie',
        status: false,
      },
    ],
  },
];

type QuestionType = {
  messages: {
    round: string;
    default: string;
    correct: string;
    error: string;
  };
  song: {
    name: string;
    link: HTMLAudioElement;
    artist: string;
  };
  options: Option[];
};

type Option = {
  label: string;
  status: boolean;
};

function Rounds() {
  const DefaultRound = {
    attemps: 4,
    duration1: 5,
    duration2: 10,
    selectedAnswer: '',
    win: false,
  };

  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType>(roundsData[questionIndex]);
  const [currentRound, setCurrentRound] = useState(DefaultRound);
  const [isNeedleUp, setNeedleUp] = useState(false);
  const [discAnimation, setDiscAnimation] = useState(false);

  const [isTitleIn, setIsTitleIn] = useState(true);
  const [isSubtitleIn, setIsSubtitleIn] = useState(true);
  const [lastRound, setLastRound] = useState(false);
  const [isQuizOver, setIsQuizOver] = useState(false);
  const navigate = useNavigate();

  const goToForm = () => {
    navigate('/form');
  };

  const clearAllPendingTimeouts = () => {
    const highestId = window.setTimeout(() => {
      for (let i = highestId; i >= 0; i--) {
        window.clearInterval(i);
      }
    }, 0);
  };

  const playSong = () => {
    setNeedleUp(false);
    setTimeout(() => {
      currentQuestion?.song.link.play();
    }, REPEAT_SONG_DELAY * 1000);
    setDiscAnimation(true);
    if (currentRound.attemps === 4 || currentRound.attemps === 3) {
      setTimeout(() => {
        currentQuestion?.song.link.pause();
        setDiscAnimation(false);
      }, DefaultRound.duration1 * 1000);
    }
    if (currentRound.attemps < 3) {
      setTimeout(() => {
        currentQuestion?.song.link.pause();
        setDiscAnimation(false);
      }, DefaultRound.duration2 * 1000);
    }
    if (currentQuestion?.song.link !== undefined) {
      currentQuestion.song.link.currentTime = 0;
    }
    setCurrentRound({
      ...currentRound,
      selectedAnswer: '',
    });
  };

  const fadeTitle = (time: number) => {
    setIsTitleIn(false);
    setTimeout(() => {
      setIsTitleIn(true);
    }, time);
  };

  const fadeSubtitle = (time: number) => {
    setIsSubtitleIn(false);
    setTimeout(() => {
      setIsSubtitleIn(true);
    }, time);
  };

  const titleAnimSyncWithSong = () => {
    if (currentRound.selectedAnswer !== '') {
      fadeTitle(REPEAT_SONG_TITLE_ANIMATION_DURATION);
    }
    setTimeout(() => {
      playSong();
    }, REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
  };

  const selectAnswer = (artist: string) => {
    setDiscAnimation(false);
    clearAllPendingTimeouts();
    currentQuestion?.song.link.pause();
    if (currentRound.win === false && currentRound.selectedAnswer !== '') return;
    if (currentRound.win === true) return;
    setIsTitleIn(false);
    setTimeout(() => {
      if (artist === currentQuestion?.song.artist && currentRound.attemps !== 0) {
        setIsTitleIn(true);
        setCurrentRound({
          ...currentRound,
          selectedAnswer: artist,
          win: true,
          attemps: currentRound.attemps - 1,
        });
        if (questionIndex === roundsData.length - 1) {
          setLastRound(true);
          setTimeout(() => {
            setIsQuizOver(true);
          }, GO_TO_FORM_SCREEN * 1000);
        }
        return;
      }
      setIsTitleIn(true);
      setCurrentRound({
        ...currentRound,
        selectedAnswer: artist,
        attemps: currentRound.attemps - 1,
        win: false,
      });
      setNeedleUp(true);
      return;
    }, TITLE_ANIMATION_TRANSITION_DURATION * 1000);
  };

  const shuffleArray = (array: Option[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const nextRound = () => {
    setQuestionIndex(questionIndex + 1);
    fadeSubtitle(REPEAT_SONG_TITLE_ANIMATION_DURATION);
    fadeTitle(REPEAT_SONG_TITLE_ANIMATION_DURATION);
  };

  useEffect(() => {
    setCurrentQuestion(roundsData[questionIndex]);
    if (currentRound.selectedAnswer === '') {
      setTimeout(() => {
        playSong();
      }, NEXT_ROUND_SONG_DELAY_DURATION * 1000);
    }
    setCurrentRound({ ...DefaultRound });
  }, [questionIndex, currentQuestion]);

  useEffect(() => {
    if (questionIndex < roundsData.length) {
      setTimeout(() => {
        setCurrentQuestion({
          ...roundsData[questionIndex],
          options: shuffleArray(roundsData[questionIndex].options),
        });
      }, REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
    }
    if (isQuizOver) {
      goToForm();
    }
  }, [questionIndex, isQuizOver]);

  return (
    <Container>
      <BackgroundContainer>
        {currentRound.win === true && <Background src={whiteDisc} />}
      </BackgroundContainer>
      <Header text="sponsored by" />
      {currentQuestion && (
        <QuestionContainer>
          <TitleContainer>
            <Title
              className={isTitleIn ? 'appear' : 'disappear'}
              style={
                currentRound.selectedAnswer !== '' && currentRound.win === false
                  ? { color: '#dd5e80' }
                  : {}
              }
              textLenght={
                (currentRound.selectedAnswer === '' && currentQuestion.messages.default.length) ||
                (currentRound.selectedAnswer !== '' && currentRound.win === false
                  ? currentQuestion.messages.error.length
                  : currentQuestion.messages.correct.length)
              }>
              {(currentRound.selectedAnswer === '' && currentQuestion.messages.default) ||
                (currentRound.selectedAnswer !== '' && currentRound.win === false
                  ? currentQuestion.messages.error
                  : currentQuestion.messages.correct)}
            </Title>
          </TitleContainer>
          <SubtitleContainer>
            <Subtitle className={isSubtitleIn ? 'appear' : 'disappear'}>
              {currentQuestion.messages.round}
            </Subtitle>
          </SubtitleContainer>
          {currentRound.win ? (
            <SuccessContainer className={currentRound.win === true ? 'zoomIn' : 'zoomOut'}>
              <SuccessSong>"{currentQuestion.song.name}"</SuccessSong>
              <SuccessArtist>by {currentQuestion.song.artist}</SuccessArtist>
              {!lastRound && (
                <NextRoundButton text={messages.nextRound} onClick={() => nextRound()} />
              )}
            </SuccessContainer>
          ) : (
            <ResultContainer>
              <NeedleContainer>
                <NeedleRound
                  src={Needle}
                  onClick={() => titleAnimSyncWithSong()}
                  className={isNeedleUp ? 'needle-up' : 'needle-down'}
                />
              </NeedleContainer>
              {isNeedleUp && (
                <MessageContainer className={isTitleIn ? 'zoomIn' : 'zoomOut'}>
                  <p>{messages.needle}</p>
                </MessageContainer>
              )}
              <DiscRound src={Disc} className={discAnimation ? 'run-animation' : ''} />
            </ResultContainer>
          )}
          <AnswerContainer className={isSubtitleIn ? 'appear' : 'disappear'}>
            {currentQuestion.options.map((option) => (
              <StyledOption
                option={option}
                selected={currentRound.selectedAnswer}
                onClick={() => selectAnswer(option?.label)}
              />
            ))}
          </AnswerContainer>
        </QuestionContainer>
      )}
    </Container>
  );
}

export default Rounds;
